/* eslint-disable react/jsx-no-useless-fragment */
import React, { ReactNode } from 'react';

interface IfProps {
    condition: boolean;
    children: ReactNode;
}

/** @namespace Pwa/Component/If/Component */
export class IfComponent extends React.Component<IfProps> {
    render() {
        const { condition, children } = this.props;

        return <>{ condition ? <>{ children }</> : null }</>;
    }
}

export default IfComponent;
