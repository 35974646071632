import download_template from './assets/download_template.csv';
import facebookIcon from './assets/facebook.svg';
import instagramIcon from './assets/instagram.svg';
import logo_black from './assets/inverted_logo.svg';
import logo_lkq from './assets/lkq_white.svg';
import logo from './assets/logo.svg';
import logo_white from './assets/logo_inverted.svg';
import menu from './assets/menu.svg';
import motoIcon from './assets/moto.svg';
import sort from './assets/sort_icon.svg';
import youtubeIcon from './assets/youtube.svg';

export const SOCIAL = {
    isItemsHorizontal: true,
    items: [
        {
            href: 'https://www.facebook.com/motorparts.topperformances.dr',
            src: facebookIcon,
            title: __('Facebook'),
        },
        {
            href: 'https://www.instagram.com/motorparts_official',
            src: instagramIcon,
            title: __('Instagram'),
        },
        {
            href: 'https://www.youtube.com/@motorpartstopperformances-6257',
            src: youtubeIcon,
            title: __('Youtube'),
        },
    ],
};

export const MOTO = motoIcon;

export const LOGO = logo;
export const LOGO_WHITE = logo_white;
export const LOGO_LKQ = logo_lkq;
export const LOGO_BLACK_FULL = logo_black;
export const MENU = menu;
export const SORT_ICON = sort;
export const SAMPLE_CSV = download_template;
